$(document).ready(function () {

	function OpenWindow(targetWindow) {
		$(targetWindow).arcticmodal({
			closeOnEsc: false,
			closeOnOverlayClick: false,
			openEffect: {
				type: "none"
			},
			overlay: {
				css: {
					backgroundColor: "#000",
					opacity: .60
				}
			}
		});
	}

	$(".js-spin_button").click(function () {

		$(this).addClass("disabled");

		$(".js-slot").addClass("spin_start");

		setTimeout(function () {
			$(".js-slot").addClass("success");
		}, 1500);

		setTimeout(function () {
			OpenWindow(".js-order_window");
		}, 3000);

	});

});

function showHidePassword(target) {
    let input = document.getElementById('password-input');
    if (input.getAttribute('type') == 'password') {
        target.classList.add('view');
        input.setAttribute('type', 'text');
    } else {
        target.classList.remove('view');
        input.setAttribute('type', 'password');
    }
    return false;
}

window.showHidePassword = showHidePassword;